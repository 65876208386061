/* 
Don't add css in here please, use CSS-in-JS */

@font-face {
  font-family: "Gordita";
  src: url("./fonts/Gordita-Regular.eot");
  src: url("./fonts/Gordita-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Gordita-Regular.woff2") format("woff2"), url("./fonts/Gordita-Regular.woff") format("woff"),
    url("./fonts/Gordita-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gordita";
  src: url("./fonts/Gordita-Medium.eot");
  src: url("./fonts/Gordita-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Gordita-Medium.woff2") format("woff2"), url("./fonts/Gordita-Medium.woff") format("woff"),
    url("./fonts/Gordita-Medium.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

body,
html,
#root {
  height: 100%;
}

#root > div {
  height: 100%;
}
